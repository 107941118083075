import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { } from "../../features/accountSlice/accountSlice";
import { loadParksActionCreator } from "../../features/parksSlice/parksSlice";
import {
  loadingActionCreator,
  notLoadingActionCreator,
} from "../../features/uiSlice/uiSlice";
import { IFilters, IPark } from "../../types/parkInterfaces";
import { toast } from "react-toastify";
import { loadParkDetailsActionCreator } from "../../features/parkSlice/parkSlice";

const url: string = `${process.env.REACT_APP_API_URL}/parks`;

const getAuth = () => {
  const token = localStorage.getItem("token");
  return {
    headers: { Authorization: `Bearer ${token}` },
  };
};

export const loadParksThunk =
  (filter?: IFilters, urlPath?: string) => async (dispatch: Dispatch) => {
    try {
      let query = "";
      if (filter) {
        if (filter.owner) {
          query += "&owner=" + filter.owner;
        }
        if (filter.city) {
          query += "&city=" + filter.city;
        }
        if (filter.ids) {
          query += "&ids=" + filter.ids;
        }

        if (filter.near) {
          query += "&near=" + filter.near;
        }

        if (filter.unverified) {
          query += "&unverified=true";
        }

      }

      const { data, status } = await axios.get(
        urlPath ?? `${url}/list?${query}`
      );

      if (status === 200) {
        dispatch(loadParksActionCreator(data));
      }
    } finally {
      dispatch(notLoadingActionCreator());
    }
  };

export const deleteParkThunk = (id: string) => async (dispatch: Dispatch) => {
  dispatch(loadingActionCreator());
  const { status } = await axios.delete(`${url}/${id}`, getAuth());
  if (status === 200) {
    toast.success("Park deleted!");
  } else {
    toast.error(`Error deleting the park`);
  }
  dispatch(notLoadingActionCreator());
};

const getConfigCreateUpdate = () => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
};

export const createParkThunk =
  (park: IPark) => async (dispatch: Dispatch) => {
    try {
      dispatch(loadingActionCreator());

      const { status } = await axios.post(
        `${url}/v2`,
        park,
        getConfigCreateUpdate()
      );
      if (status === 201) {
        toast.success("Park created! Thanks!");
      } else {
        toast.error(`Error creating the park`);
      }
    } finally {
      dispatch(notLoadingActionCreator());
    }
  };

export const editParkThunk =
  (park: IPark) => async (dispatch: Dispatch) => {
    try {
      dispatch(loadingActionCreator());

      await axios.put(
        `${url}/v2/${park.id}`,
        park,
        getConfigCreateUpdate()
      );

      toast.success("Park Updated! Thanks!");
    } catch (e) {
      toast.error(`Error updating the park`);
    } finally {
      dispatch(notLoadingActionCreator());
    }
  };

export const getParkDetailThunk =
  (id: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(loadingActionCreator());
      const { data, status } = await axios.get(`${url}/${id}`);
      if (status === 200) {
        dispatch(loadParkDetailsActionCreator(data));
      }
    } catch (error: any) {
      toast.error("There was a problem with the details, try again");
      return error.message;
    } finally {
      dispatch(notLoadingActionCreator());
    }
  };

export const verifyPark = (id: String) => async (dispatch: Dispatch) => {
  try {
    dispatch(loadingActionCreator());

    await axios.put(
      `${url}/${id}/verify`,
      {},
      getConfigCreateUpdate()
    );

    toast.success("Park Verfied! Thanks!");
  } catch (e) {
    toast.error(`Error verifing the park`);
  } finally {
    dispatch(notLoadingActionCreator());
  }
}
