import { LenghtStyles } from "./LengthStyles";

const Length = ({ length }) => {
    return (<LenghtStyles>
        {<div>
            {length < 1000 && `${length} mts`}
            {length >= 1000 && `${(length / 1000).toFixed(2)}km`}
        </div>}
    </LenghtStyles>)
}

export default Length;
