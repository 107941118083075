import React, { ChangeEvent, FormEvent, Suspense, useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hooks/hooks";
import {
  createParkThunk,
  editParkThunk,
} from "../../redux/thunks/parkThunk/parkThunk";
import { IAddress, IPark, ParkDetail } from "../../redux/types/parkInterfaces";
import Map from "../Map/Map";
import { ParkFormStyles } from "./ParkFormStyles";
import {
  GiWateringCan,
  GiKidSlide,
  GiParkBench,
  GiTable,
  GiBasketballBasket,
  GiWoodenFence,
  GiWeightLiftingUp,
} from "react-icons/gi";
import { BsTreeFill } from "react-icons/bs";
import { BiBeer } from "react-icons/bi";
import { MdPool } from "react-icons/md";
import { RiBikeLine, RiPingPongFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import MultiImagePicker from "../MultiImagePicker/MultiImagePicker";
import axios from "axios";
import AddressForm from "../AddressForm/AddressForm";

const TOKEN = localStorage.getItem("token");
const ADDRESS_AC_URL = `${process.env.REACT_APP_API_URL}/location`;

export interface ParkFormProps {
  park?: IPark;
  edit: boolean;
}

const imagesInitialValue = (park) => {
  if (!park?.photos)
    return []

  return park.photos.map(image => {
    let imageId = image.replace("https://", "")
    imageId = imageId.substring(imageId.indexOf("/") + 1)

    return (
      {
        previewUrl: image,
        id: imageId,
      })
  })

}

const ParkForm = ({ park: currentPark, edit }: ParkFormProps) => {
  const [parkLocation, setParkLocation] = useState<[number, number]>([
    41.388014160598885, 2.185983541021393,
  ]);

  const [park, setPark] = useState<IPark>(
    currentPark ?? {}
  );

  const [address, setAddress] = useState<IAddress>(
    currentPark?.address ?? {}
  );

  const [images, setImages] = useState(imagesInitialValue(currentPark));

  const [step, setStep] = useState(0);

  const [checkedDetails, setCheckedDetails] = useState<any>(
    currentPark?.details ?? []
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const _setStep = (val) => {

    if (val === 3 && !address.name) {     
      axios.get(`${ADDRESS_AC_URL}?latitude=${parkLocation[0]}&longitude=${parkLocation[1]}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`
        }
      }).then(({ data })=> {
        if (data && data[0]) {
          setAddress({ ...data[0], name: data[0].name !== "" ? data[0].name : data[0].address })
          
        }
      });
    } 
    
    setStep(val);
    

  }

  useEffect(() => {
    if (edit && currentPark) {
      if (window && window.scrollTo){
        window.scrollTo(0, 0);
      }
      setParkLocation([
        currentPark.location.coordinates[0],
        currentPark.location.coordinates[1],
      ]);
    } else if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setParkLocation([position.coords.latitude, position.coords.longitude]);
      });
    }
  }, [edit, currentPark]);

  const changeData = (event: ChangeEvent<HTMLInputElement>) => {
    setPark({ ...park, [event.target.id]: event.target.value });
  };

  const submitPark = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (edit) {
      await dispatch(
        editParkThunk(
          {
            ...park,
            address,
            details: checkedDetails,
            location: { type: "Point", coordinates: parkLocation },
            photos: [...images.map(image => image.id)]
          }
        )
      );

      navigate(`/park/${park.id}`);
    } else {
      await dispatch(
        createParkThunk(
          {
            ...park,
            address,
            details: checkedDetails,
            location: { type: "Point", coordinates: parkLocation },
            photos: [...images.map(image => image.id)]
          }
        )
      );
      navigate(`/parks`);
    }
  };

  const parkDetailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const detail = event.target.id;
    if (checkedDetails.includes(detail)) {
      setCheckedDetails(checkedDetails.filter((det: any) => det !== detail));
    } else {
      setCheckedDetails([...checkedDetails, detail]);
    }
  };


  return (
    <ParkFormStyles>
      <div className="main-container">
        <form onSubmit={submitPark}>
          <h2>{!edit ? "Create a new park" : "Edit your park"}</h2>
          {step === 0 && (
            <div className="container-steps">
              <h3>{!edit ? "Select park location" : "Edit park Location"}</h3>
              <Map
                onLocationSelected={(loc) => setParkLocation(loc)}
                location={parkLocation}
              ></Map>
            </div>
          )}

          {step === 1 && (
            <div className="container-steps--photos">
              <h3>
                {!edit
                  ? "Upload pictures"
                  : "Change your pictures or add new ones"}
              </h3>
              <MultiImagePicker
                images={images}
                onChange={(newImages) => setImages(newImages)} />
            </div>
          )}

          {step === 2 && (
            <div className="container-steps--icons">
              <h3>
                {!edit
                  ? "What does this park have?"
                  : "Change details from the park"}
              </h3>
              <div className="container-icons">
                <label>
                  <div className="items">
                    <GiKidSlide />
                    {ParkDetail.swing}
                  </div>
                  <input
                    id={ParkDetail.swing}
                    type="checkbox"
                    checked={checkedDetails.includes(ParkDetail.swing)}
                    onChange={parkDetailChange}
                  ></input>
                </label>
                <label>
                  <div className="items">
                    <GiWeightLiftingUp />
                    {ParkDetail.calistenia}
                  </div>
                  <input
                    id={ParkDetail.calistenia}
                    type="checkbox"
                    checked={checkedDetails.includes(ParkDetail.calistenia)}
                    onChange={parkDetailChange}
                  ></input>
                </label>
                <label>
                  <div className="items">
                    <GiWateringCan />
                    {ParkDetail.water}
                  </div>
                  <input
                    id={ParkDetail.water}
                    type="checkbox"
                    checked={checkedDetails.includes(ParkDetail.water)}
                    onChange={parkDetailChange}
                  ></input>
                </label>
                <label>
                  <div className="items">
                    <BsTreeFill />
                    {ParkDetail.shadow}
                  </div>
                  <input
                    id={ParkDetail.shadow}
                    type="checkbox"
                    checked={checkedDetails.includes(ParkDetail.shadow)}
                    onChange={parkDetailChange}
                  ></input>
                </label>
                <label>
                  <div className="items">
                    <GiWoodenFence />
                    {ParkDetail.fence}
                  </div>
                  <input
                    id={ParkDetail.fence}
                    type="checkbox"
                    checked={checkedDetails.includes(ParkDetail.fence)}
                    onChange={parkDetailChange}
                  ></input>
                </label>
                <label>
                  <div className="items">
                    <BiBeer />
                    {ParkDetail.bar}
                  </div>
                  <input
                    id={ParkDetail.bar}
                    type="checkbox"
                    checked={checkedDetails.includes(ParkDetail.bar)}
                    onChange={parkDetailChange}
                  ></input>
                </label>
                <label>
                  <div className="items">
                    <MdPool />
                    {ParkDetail.swimmingPool}
                  </div>
                  <input
                    id={ParkDetail.swimmingPool}
                    type="checkbox"
                    checked={checkedDetails.includes(ParkDetail.swimmingPool)}
                    onChange={parkDetailChange}
                  ></input>
                </label>
                <label>
                  <div className="items">
                    <GiParkBench />
                    {ParkDetail.bench}
                  </div>
                  <input
                    id={ParkDetail.bench}
                    type="checkbox"
                    checked={checkedDetails.includes(ParkDetail.bench)}
                    onChange={parkDetailChange}
                  ></input>
                </label>
                <label>
                  <div className="items">
                    <GiTable />
                    {ParkDetail.picnic}
                  </div>
                  <input
                    id={ParkDetail.picnic}
                    type="checkbox"
                    checked={checkedDetails.includes(ParkDetail.picnic)}
                    onChange={parkDetailChange}
                  ></input>
                </label>
                <label>
                  <div className="items">
                    <RiPingPongFill />
                    {ParkDetail.pingPong}
                  </div>
                  <input
                    id={ParkDetail.pingPong}
                    type="checkbox"
                    checked={checkedDetails.includes(ParkDetail.pingPong)}
                    onChange={parkDetailChange}
                  ></input>
                </label>
                <label>
                  <div className="items">
                    <GiBasketballBasket />
                    {ParkDetail.basket}
                  </div>
                  <input
                    id={ParkDetail.basket}
                    type="checkbox"
                    checked={checkedDetails.includes(ParkDetail.basket)}
                    onChange={parkDetailChange}
                  ></input>
                </label>
                <label>
                  <div className="items">
                    <RiBikeLine />
                    {ParkDetail.bike}
                  </div>
                  <input
                    id={ParkDetail.bike}
                    type="checkbox"
                    checked={checkedDetails.includes(ParkDetail.bike)}
                    onChange={parkDetailChange}
                  ></input>
                </label>
              </div>
            </div>
          )}

          {step === 3 &&
            <div className="container-name">
              <h3>
                {!edit ? "Let others find it!" : "Edit your park details"}
              </h3>
              <input
                type="text"
                id="description"
                onChange={changeData}
                value={park.description}
                placeholder="Description"
                autoComplete="off"
              ></input>
              <AddressForm address={address} onChangeData={(newAddress) => setAddress(newAddress)} />
            </div>}
          {step >= 3 && !edit && (
            <button className="formButton button-create" type="submit">
              Create
            </button>
          )}

          {step >= 3 && edit && (
            <button className="formButton button-create" type="submit">
              Edit
            </button>
          )}
          <div className="buttons">
            {step >= 1 && (
              <button
                className="formButton button-previous"
                type="button"
                onClick={() => _setStep(step - 1)}
              >
                Previous
              </button>
            )}
            {step < 3 && (
              <button
                className="formButton button-next"
                type="button"
                onClick={() => _setStep(step + 1)}
              >
                Next
              </button>
            )}
          </div>
        </form>
      </div>
    </ParkFormStyles>
  );
};

export default ParkForm;
