import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks/hooks";
import { IPark } from "../../redux/types/parkInterfaces";
import CarouselPark from "../CarouselPark/CarouselPark";
import DetailsIcons from "../DetailsIcons/DetailsIcons";
import Map from "../Map/Map";
import { DetailParkStyles } from "./DetailParkStyles";
import { accountSelector } from "../../redux/features/accountSlice/accountSlice";
import Distance from "../Distance/Distance";
import { isAdminSelector } from "../../redux/features/authSlice/authSlice";

interface Props {
  park: IPark;
  onVerifyPark: any;
}

const DetailPark = ({ park, onVerifyPark }: Props) => {
  const {
    id,
    name,
    description,
    photos,
    location,
    details,
    owner,
    address,
    distance,
    unverified
  } = park;

  const navigate = useNavigate();
  const { loggedIn, id: userId } = useAppSelector(accountSelector);
  const isAdmin = useAppSelector(isAdminSelector);

  return (
    <DetailParkStyles>
      <div className="main-container">
        <h1>{name}</h1>
        <CarouselPark photos={photos} />
        <p className="description">{description}</p>
        <div className="body-container">
          <Distance distance={distance} toCoordinate={location.coordinates} />
          <DetailsIcons details={details} />
          {loggedIn && userId === owner && (
            <button
              onClick={() => {
                navigate(`/park/${id}/edit`);
              }}
            >
              Edit
            </button>
          )}
          {isAdmin && unverified &&
            <button
              onClick={() => {
                onVerifyPark(id);
              }} 
              >
                Verify
              </button>
          }
        </div>
        {address?.city &&
          <p>
            {address?.city}, {address?.address}
          </p>
        }

        {location.coordinates && location.coordinates[0] && (
          <Map
            location={[location.coordinates[0], location.coordinates[1]]}
          ></Map>
        )}
      </div>
    </DetailParkStyles>
  );
};

export default DetailPark;
