import styled from "styled-components";


export const Gallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center
`;


export const GalleryImage = styled.div`
  display: flex;
  
  .imageContainer {
    width: 150px;
    height: 150px;
    display: flex;
    align-content: center;
    //border-style: solid;
    padding: 5px;
    overflow: hidden;
    position: relative;
  }
  img {
    width: 100%;
    border-radius: 10%;
    object-fit: cover;
  }
  
  .deleteImageButton{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-around;
    top: -.2px;
    right: -.2px;
    width: 25px;
    height: 25px;
    background-size: 100%;
    border-radius: 50%;
    background-color: black;
    padding: 2px;
    border: none;
  }
  
  .deleteImageIcon {
    width: 80%;
    height: 80%;
    color: white;
    stroke-width: 1px;
  }
  
  input {
    display: none;
  }

`;
