export interface IParkState {
  page: number;
  pageSize: number;
  next?: string;
  previous?: string;
  total: number;
  filters: IFilters;
  results: IPark[];
}

export interface IFilters {
  owner?: string;
  ids?: string;
  city?: string;
  near?: string;
  unverified?:boolean;
}
export interface ILocation {
  type: string;
  coordinates: number[];
}

export interface IParkList {
  results: IPark[];
}

export interface IPark {
  id?: string; 
  name?: string;
  description?: string;
  photos?: string[];
  location?: ILocation;
  details?: string[];
  owner?: string;
  address?: IAddress;
  distance?: number;
  unverified?: boolean;
}

export interface IAddress {
  name?: string;
  city?: string;
  address?: string;
  number?: string;
  cp?: string;
  countryCode?: string;
  fullAddress?: string;
}

export const ParkDetail = {
  swing: "Swing",
  calistenia: "Calistenia",
  water: "Water",
  shadow: "Shadow",
  fence: "Fence",
  bar: "Bar",
  swimmingPool: "SwimmingPool",
  bench: "Bench",
  picnic: "Picnic",
  pingPong: "PingPong",
  basket: "Basket",
  bike: "Bike",
};
