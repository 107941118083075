import { ParkStyles } from "./ParkStyles";
import { BsStarFill, BsInfo } from "react-icons/bs";
import { IPark } from "../../redux/types/parkInterfaces";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/hooks";
import { accountSelector } from "../../redux/features/accountSlice/accountSlice";
import {
  addFavouriteThunk,
  deleteFavouriteThunk,
} from "../../redux/thunks/accountThunk/accountThunk";
import { useNavigate } from "react-router-dom";
import DetailsIcons from "../DetailsIcons/DetailsIcons";
import {
  deleteParkThunk,
  loadParksThunk,
} from "../../redux/thunks/parkThunk/parkThunk";
import Distance from "../Distance/Distance";

const Park = ({
  id, 
  name,
  photos,
  details,
  address,
  location,
  owner,
  distance,
  description,
}: IPark) => {
  const [imageUrl, setImageUrl] = useState<string>(
    `${photos[0]}`
  );

  const [isFavourite, setIsFavourite] = useState<boolean>(false);

  const { favParks, id: userId } = useAppSelector(accountSelector);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (favParks)
      setIsFavourite(favParks.includes(id!));
  }, [favParks, id]);

  const onImageError = (event: any) => {
    setImageUrl("images/columpiaDa.png");
  };

  return (
    <ParkStyles>
      <section>
        <div className="card">
          <img
            className="card-image"
            src={imageUrl}
            alt="beautiful forest"
            onError={onImageError}
          />
          <div className="underPicture">
            {owner === userId && (
              <div
                className="deletepark"
                onClick={async () => {
                  await dispatch(deleteParkThunk(id));
                  dispatch(loadParksThunk());
                }}
              >
                Delete Park
              </div>
            )}

            <Distance toCoordinate={location.coordinates} />
          </div>
          <div className="card-body">
            <h3 className="card-title">{name}</h3>
            <span>
              {description}
            </span>
            <DetailsIcons details={details} />
          </div>

          <div className="button-container">
            <button
              className="button button--info"
              onClick={() => navigate(`/park/${id}`)}
            >
              <BsInfo className="mobile-button" />
              <span className="desktop-button">Details</span>
            </button>

            {!isFavourite && (
              <button
                className="button button--favourite"
                onClick={() => dispatch(addFavouriteThunk(id!))}
              >
                <BsStarFill className="mobile-button" />
                <span className="desktop-button">Save</span>
              </button>
            )}
            {isFavourite && (
              <button
                className="button button--favourite"
                onClick={() => dispatch(deleteFavouriteThunk(id!))}
              >
                <BsStarFill className="mobile-button delete" />
                <span className="desktop-button unsave">Saved</span>
              </button>
            )}
          </div>
        </div>
      </section>
    </ParkStyles>
  );
};

export default Park;
