import { BsStarFill, BsInfo } from "react-icons/bs";
import { IPark } from "../../redux/types/parkInterfaces";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/hooks";
import { accountSelector } from "../../redux/features/accountSlice/accountSlice";
import {
  addFavouriteThunk,
  deleteFavouriteThunk,
} from "../../redux/thunks/accountThunk/accountThunk";
import { useNavigate } from "react-router-dom";
import { ParkminiStyles } from "./ParkminiStyles";
import Distance from "../Distance/Distance";

const Park = ({
  id,  
  name,
  photos,  
  details,
  address,
  owner,
  location
}: IPark) => {
  const [imageUrl, setImageUrl] = useState<string>(
    `${photos[0]}`
  );

  const [isFavourite, setIsFavourite] = useState<boolean>(false);

  const { favParks } = useAppSelector(accountSelector);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (favParks)
      setIsFavourite(favParks.includes(id!));
  }, [favParks, id]);

  const onImageError = (event: any) => {
    setImageUrl("images/columpiaDa.png");
  };

  return (
    <ParkminiStyles>
      <section>
        <div className="card">
          <img
            src={imageUrl}
            alt="beautiful forest"
            onError={onImageError}
          />

          <div className="card-body">
            <div className="distance">
              <Distance distance={undefined} toCoordinate={location.coordinates} />
            </div>
            <h3>{name}</h3>
          </div>

          <div className="button-container">
            <button
              className="button button--info"
              onClick={() => navigate(`/park/${id}`)}
            >
              <BsInfo className="mobile-button" />
              <span className="desktop-button">Details</span>
            </button>

            {!isFavourite && (
              <button
                className="button button--favourite"
                onClick={() => dispatch(addFavouriteThunk(id!))}
              >
                <BsStarFill className="mobile-button" />
                <span className="desktop-button">Save</span>
              </button>
            )}
            {isFavourite && (
              <button
                className="button button--favourite"
                onClick={() => dispatch(deleteFavouriteThunk(id!))}
              >
                <BsStarFill className="mobile-button delete" />
                <span className="desktop-button unsave">Saved</span>
              </button>
            )}
          </div>
        </div>
      </section>
    </ParkminiStyles>
  );
};

export default Park;
