import PointsMap from "../../components/PointsMap/PointsMap";
import { parkPageSelector } from "../../redux/features/parksSlice/parksSlice";
import { useAppSelector } from "../../redux/hooks/hooks";
import styled from "styled-components";

export const MapPageStyles = styled.div`
  overflow-x: hidden;

  h1 {
    color: #0cc8d0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
  }
`;

const MapPage = () => {
  const parks = useAppSelector(parkPageSelector);

  return (
    <MapPageStyles>
      <h1>Look for the closest parks</h1>
      <PointsMap {...parks} />
    </MapPageStyles>
  );
};

export default MapPage;
