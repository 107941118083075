import styled from "styled-components";

export const ParkminiStyles = styled.div`
  background-color: white;

  section {
    display: flex;
    justify-content: center;

    .card {
      display :flex;
      width: 100%;
      flex-direction: column;

      img {
        width: 100%;
        max-height: 200px;
        border-radius: 8px;
        box-shadow: 8px 2px 12px 0 rgba(0, 0, 0, 0.2); 
      }

      .card-body {
        .distance {
          display: flex;
          flex-direction: row-reverse;
        }

        h3 {
          color: #00c2cb;
          font-size: 13px;
          overflow: hidden;
          max-width: 150px;
          font-size: 16px;
          margin: 12px 0;
          transition: 0.4s;
          font-family: "Roboto";
          letter-spacing: 2px;
        }
      }

      .button-container {
        padding-top: 10px;
        display: flex;
        justify-content: space-between;

        .unsave {
          opacity: 50%;
          color: #00c2cb;
        }

        .button {
          width: 120px;
          height: 40px;
          border-radius: 12px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border: none;
          color: white;
          font-size: 20px;
          font-weight: bold;
          cursor: pointer;

          &--info {
            background-color: #00c2cb;
          }
          &--favourite {
            background-color: #c9e265;
          }
          svg {
            fill: #fff;
            width: 38px;
            height: 38px;
            display: none;
          }
        }
      }
    }
  }
`;
