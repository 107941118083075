const AddressForm = ({ address, onChangeData }) => {

    const changeAddressData = (event) => {
        onChangeData({ ...address, [event.target.id]: event.target.value });
    };


    return <>
        <label htmlFor="name">Name</label>
        <input
            type="text"
            id="name"
            onChange={changeAddressData}
            value={address.name}
            placeholder="Park Name"
            autoComplete="off"
        ></input>
        <label htmlFor="description">Description</label>

        <label htmlFor="city">City</label>
        <input
            type="text"
            id="city"
            onChange={changeAddressData}
            value={address.city}
            placeholder="City"
            autoComplete="off"
        ></input>
        <label htmlFor="address">Address</label>
        <input
            type="text"
            id="fullAddress"
            onChange={changeAddressData}
            value={address.fullAddress}
            placeholder="Address"
            autoComplete="off"
        ></input>
    </>

};


export default AddressForm;
