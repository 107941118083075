// @ts-ignore
import { useEffect, useState } from "react";
import { Carousel } from "react-carousel-minimal";

const CarouselPark = ({ photos }: any) => {
  const [data, setData] = useState([])

  useEffect(() => {
    setData(photos.map((photo: any) => ({
      image: `${photo}`,
      caption: "",
    })));
  }, [photos]);

  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };
  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };
  return (
    <div className="App">
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            padding: "0 20px",
          }}
        >
          {data && data[0] && (
            <Carousel
              data={data}
              time={2000}
              width="850px"
              height="500px"
              captionStyle={captionStyle}
              radius="10px"
              slideNumber={true}
              slideNumberStyle={slideNumberStyle}
              captionPosition="bottom"
              automatic={true}
              dots={true}
              pauseIconColor="white"
              pauseIconSize="40px"
              slideBackgroundColor="darkgrey"
              slideImageFit="cover"
              thumbnails={false}
              thumbnailWidth="100px"
              style={{
                textAlign: "center",
                maxWidth: "850px",
                maxHeight: "500px",
                margin: "40px auto",
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default CarouselPark;
