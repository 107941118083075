import { useEffect, useState } from "react";
import Length from "../Length/Length";
import { DistanceStyles } from "./DistanceStyles"
import { getDistance } from "geolib";

const Distance = ({ distance: paramDistance = undefined, toCoordinate = undefined }) => {

    const [distance, setDistance] = useState(paramDistance);

    useEffect(() => {
        if (navigator?.geolocation && toCoordinate)
            navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
                const currentDistance = getDistance({ latitude, longitude },
                    { latitude: toCoordinate[0], longitude: toCoordinate[1] })
                setDistance(currentDistance)
            }, (error) => {

            });

    })


    return (<DistanceStyles>
        { distance &&
            <Length length={distance} />
        }

    </DistanceStyles>)
}

export default Distance;
