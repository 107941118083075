import { useState } from "react";
import ImagePicker from "./ImagePicker";
import { Gallery } from "./MultiImagePicker.styles";

const MultiImagePicker = ({ images, onChange }) => {

    const [loading, setLoading] = useState(false)

    const onImagesSelected = (file) => {
        setLoading(true)
    }

    const onImagesUploaded = (selectedImages) => {
        onChange([...images, ...selectedImages])
        setLoading(false)
    }

    const onDeleteImage = (deletedImage) => {
        onChange([...images.filter(si => si.id !== deletedImage.id)]);
    }

    return (
        <Gallery>
            {images.map(image => <ImagePicker
                image={image}
                onDeleteImage={onDeleteImage} />)}
            <ImagePicker disabled={loading}
                onImagesSelected={onImagesSelected}
                onImagesUploaded={onImagesUploaded} />
        </Gallery>)

}

export default MultiImagePicker;
