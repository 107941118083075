import { useState } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from "react-leaflet";
import { useAppDispatch } from "../../redux/hooks/hooks";
import { loadParksThunk } from "../../redux/thunks/parkThunk/parkThunk";
import { IPark, IParkState } from "../../redux/types/parkInterfaces";
import Parkmini from "../Parkmini/Parkmini";
import { PointsMapStyles } from "./PointsMapStyles";

const PointsMap = ({ results }: IParkState) => {

  const [map, setMap] = useState(null);
  const dispatch = useAppDispatch();

  const onSearch = ({ lat, lng }) => {
    dispatch(loadParksThunk({ near: `[${lat},${lng}]` }));
  }

  const LocationMarker = () => {

    useMapEvents({
      locationfound(e) {
        map.flyTo(e.latlng, map.getZoom())
      },
    })
    return (
      <>
        {results.map((park: IPark) => (
          <Marker
            position={{
              lat: park.location.coordinates[0],
              lng: park.location.coordinates[1],
            }}
          >
            <Popup>
              <Parkmini {...park} />
            </Popup>
          </Marker>
        ))}
        ;
      </>
    )
  };

  const _onSearch = (map: { getCenter: () => any; } | null) => {

    if (map)
      onSearch(map.getCenter())
  }

  const _onLocate = (map: { locate: () => any; }) => {

    if (map) {
      map.locate();
    }

  }

  return (
    <PointsMapStyles>
      <div className="mapArea">
        <MapContainer
          center={{ lat: 41.390205, lng: 2.154007 }}
          zoom={15}
          scrollWheelZoom={true}
          className="map"
          ref={setMap}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <LocationMarker />
        </MapContainer>
      </div>
      <div className="mapExtraActions">
        <button onClick={() => _onSearch(map)}>Busca en este area</button>
        <button onClick={() => _onLocate(map)}>Mi ubicacion</button>
      </div>

    </PointsMapStyles>
  );
};

export default PointsMap;
