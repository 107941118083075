import { GalleryImage } from "./MultiImagePicker.styles";
import { BiImageAdd } from "react-icons/bi";
import axios from "axios";
import { RxCross2 } from "react-icons/rx";

const TOKEN = localStorage.getItem("token");
const url = `${process.env.REACT_APP_API_URL}/images`;

const ImagePicker = ({
    image,
    onImagesSelected,
    onImagesUploaded,
    onDeleteImage,
    disabled,
    loadingComponent = (<><p>Loading image...</p></>)
}) => {

    const renderInputElement = () => {
        return (
            <div className={"imageContainer"}>
                <label htmlFor="file-input">
                    <BiImageAdd className={"addImageIcon"} size={"100%"} />
                </label>

                <input id="file-input"
                    multiple={true}
                    type="file"
                    accept="image/*"
                    onChange={onFileSelected} />
            </div>)
    }

    const renderImage = (image) => {
        return (
            <div className={"imageContainer"}>
                <img src={image.previewUrl} alt="preview" />
                <button type="button" className={"deleteImageButton"}
                    onClick={() => onDeleteImage(image)}>
                    <RxCross2 className={"deleteImageIcon"} />
                </button>
            </div>
        )
    }

    const onFileSelected = async (event) => {
        const files = event.target.files;
        const results = [];
        onImagesSelected(files);
        for (let i = 0; i < files.length; i++) {
            let formData = new FormData();
            formData.append("image", files[i]);
            const { data } = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${TOKEN}`
                }
            });

            results.push(data)
        }
        onImagesUploaded(results);
    }


    return (
        <GalleryImage>
            {image && renderImage(image)}
            {!image && !disabled && renderInputElement()}
            {disabled && loadingComponent}
        </GalleryImage>)
}

export default ImagePicker;
