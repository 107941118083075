import styled from "styled-components";

export const PointsMapStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  .map {
    width: 100%;
    height: 450px;
    margin-bottom: 10px;
    max-width: 900px;
  }
  .mapArea {
    display: flex;
    justify-content: center;
  }

  .mapExtraActions{
    display: flex;
    justify-content: center;
  } 

`;
